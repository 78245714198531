
import { get, getWithHeader, post, postWithHeaders } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IExaQAItem, IExaSku, IExaTask } from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import TableRootEntities from "@/components/TableRootEntities.vue";

interface IReportAnswerItem {
  answer: string;
  total: number; // total times selected answer
}

interface IReportQuestionItem {
  question: string;
  answers: IReportAnswerItem[];
}

interface IReport {
  total: number; // total tests
  totalCompleted: number; // total completed tests

  items: null | IReportQuestionItem[];
}

@Component({
  components: {
    EntityPropertiesEdit,
    TableRootEntities,
  },
})
export default class ExaQuestionView extends Vue {
  public entity: IReport | null = null;

  created() {
    const id = this.$route.params.id;

    get(`/api/@examination/exa/runtime/testings/${id}/result/report`).then(async (x) => {
      console.log(`/api/@examination/exa/runtime/testings/${id}/result/report: `, x.data);

      this.entity = {
        total: x.data.length,
        totalCompleted: x.data.filter((i: { isCompleted: any }) => i.isCompleted).length,
        items: x.data[0].qas.map((qas: IExaQAItem, qindex: any) => ({
          question: qas.question,
          answers: qas.answers?.map((j: any, jindex: any) => ({
            answer: j.text,
            total: x.data.filter((k: any) => k.isCompleted && k.answers[qindex] !== undefined && k.answers[qindex].hasOwnProperty('0') && k.answers[qindex][0] == jindex).length,
          })),
        }))
      };

      console.log(`entity`, this.entity);
    });
  }

}
