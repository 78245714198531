
import { get, getWithHeader, post, postWithHeaders } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IExaQAItem, IExaSku, IExaTask } from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import TableRootEntities from "@/components/TableRootEntities.vue";


interface IExaTaskHistory {
    qas: IExaQAItem[];
    answers: number[][];
    isCompleted: boolean;
    resultScore: null | number;
    totalScore: number;
    evaluation: string;
}

@Component({
    components: {
        EntityPropertiesEdit,
        TableRootEntities,
    }
})
export default class ExaQuestionView extends Vue {

    public entity: IExaTaskHistory | null = null;

    created() {
        const id = this.$route.params.id;
        const userId = this.$route.params.userId;

        if (!userId) {
            get(`/api/@examination/exa/runtime/testings/${id}/result`).then(async x => {
                this.entity = x.data;
                console.log(`/api/@examination/exa/runtime/testings/${id}/result: `, x.data);
            });
        } else {
            get(`/api/@examination/exa/runtime/testings/${id}/result/${userId}`).then(async x => {
                this.entity = x.data;
                console.log(`/api/@examination/exa/runtime/testings/${id}/result/${userId}: `, x.data);
            });
        }
    }

    getProgress() {
        if (!this.entity) return 0;
        return (100 * this.entity.resultScore!) / this.entity!.totalScore;
    }

    getEvaluation () {

        console.log (this.entity);

        if (!this.entity || !this.entity.evaluation || this.entity.evaluation == "") return "";
        if (this.entity.resultScore == null) return "";

        // parse this.entity.evaluation as json
        const json = JSON.parse(this.entity.evaluation);

        // if json["evaluation"] is null or undefined, return ""
        if (!json["evaluation"]) return "";

        // find evaluation by score
        const result = json["evaluation"].find((x: any) => { 
            return Number(Object.keys(x)[0]) >= this.entity!.resultScore!
        });

        // if result is null or undefined, return ""
        if (!result) return "";

        console.log ("result", Object.values(result)[0])

        return Object.values(result)[0];
    }

}
