<!-- Компонент редактирования сущности -->
<template>
  <v-container class="pt-0">
    <v-form v-model="valid" @submit.prevent="submitEntity()">
      <!-- TODO submitEntity doesnt' work, thuse instead of submit used  @click -->
      <!-- <v-row class="pt-2"> -->
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, index) in properties"
            @click.prevent="
              isPropertyEditing(index) || togleEditProperty(index)
            "
            v-bind:key="index"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-content class="ml-4">
              <v-text-field
                v-model="entity[item.name]"
                :rules="[
                  (v) => (!!v && validateValue(v, item)) || item.message,
                ]"
                :label="item.caption"
                @keydown.esc="cancelEditingEntity(index)"
                @keydown.enter.stop="updateEntity()"
                autofocus
                required
                v-if="propertiesEditing[index] == true && !item.multiline"
              ></v-text-field>
              <v-textarea
                v-model="entity[item.name]"
                :value="entity[item.name]"
                :rules="[
                  (v) => (!!v && validateValue(v, item)) || item.message,
                ]"
                :label="item.caption"
                rows="1"
                auto-grow
                autofocus
                required
                v-else-if="propertiesEditing[index] == true && item.multiline"
              ></v-textarea>
              <!-- отображение тегов -->
              <v-list-item-title
                v-else-if="!propertiesEditing[index] && item.type == 'tags' && !!entity[item.name]"
              >
                <v-chip
                  class="ma-2"
                  label
                  v-for="tag in getDisplayValue(entity, item).split(/,/)"
                  :key="tag"
                  color="blue"
                  text-color="white"
                  small
                >
                  <v-icon left> mdi-label </v-icon>
                  {{ tag }}
                </v-chip>
              </v-list-item-title>
              <v-list-item-title v-else>{{
                getDisplayValue(entity, item) || item.placeholder
              }}</v-list-item-title>
            </v-list-item-content>
            <!-- кнопки сохранить и отменить -->
            <v-list-item-action v-if="isPropertyEditing(index)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-bind:key="index"
                    v-on="on"
                    :disabled="!valid"
                    type="submit"
                    @click.stop="updateEntity()"
                  >
                    <v-icon style="color: green">mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>Сохранить</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-action v-if="isPropertyEditing(index)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-bind:key="index"
                    v-on="on"
                    @click.stop="cancelEditingEntity(index)"
                  >
                    <v-icon style="color: red">mdi-cancel</v-icon>
                  </v-btn>
                </template>
                <span>Отменить</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- </v-row> -->
    </v-form>
  </v-container>
</template>

<script>
//import { stringify } from "query-string";
import config from "@/config";
import Vue from "vue";

//const API_URL = process.env.VUE_APP_BASE_API_URL + "/employee";
const API_URL_EVENTS = process.env.VUE_APP_BASE_API_URL + "/events";
const API_URL_EVENTS_RESOLUTION =
  process.env.VUE_APP_BASE_API_URL + "/event/resolution";

export default {
  name: "entity-properties-edit",
  components: {},
  props: {
    // [{icon: "mdi-...", field: "<name field in item of entity to be shown"},{...}]
    properties: {
      type: Array,
      default: () => [
        {
          icon: "mdi-domain", // mdi icon
          caption: "caption", // caption on top while editing
          placeholder: "Организация заказчика не указана", // when value is empty placeholder will be shown
          name: "customer", // field name! in `entity` object! see entity below
          type: "string", // type (doesn't supporting)
          hint: "hint", // hint  (doesn't supporting)
          message: "message", // warning message for type validation
        },
        {
          icon: "mdi-domain",
          caption: "project",
          placeholder: "project",
          name: "project",
          type: "string",
          hint: "hint",
          message: "message",
        },
      ],
    },
    entity: {
      type: Object,
    },
    transactions: {
      type: Array,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    // function for saving changes in object
    save: {
      // todo onBeforeSaving
      type: Function,
    },
    headerType: {
      type: String,
      default: "short",
    },
    // table look likes a long sheet
    sheetView: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    valid: true,
    properties__: [
      {
        icon: "mdi-domain",
        caption: "caption",
        placeholder: "Организация заказчика не указана",
        name: "customer",
        type: "string",
        hint: "hint",
        message: "message",
      },
      {
        icon: "mdi-domain",
        caption: "project",
        placeholder: "project",
        name: "project",
        type: "string",
        hint: "hint",
        message: "message",
      },
    ],
    entityCopy: {}, // used for determining changingin in entity
    propertiesEditing: [],
    addNewEventMode: false,
    selectedEvent: -1,
    eventsGroups: [
      {
        name: "confirmed",
        displayName: "Пройденный путь",
        color: "green lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "global",
        displayName: "Без даты",
        color: "blue lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "yesterday",
        displayName: "Вчера",
        color: "red lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "today",
        displayName: "Сегодня",
        color: "teal lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
      {
        name: "tomorrow",
        displayName: "Завтра",
        color: "lime lighten-5",
        filter: (x) => {
          return true || x;
        },
      },
    ],
    statusEnumeration: [
      {
        name: "newbie",
        display: "Новый",
        color: "dark blue blue--text text--darken-1",
      },
      {
        name: "onboard",
        display: "На лавке",
        color: "dark green green--text text--darken-1",
      },
      {
        name: "sick",
        display: "Больничный",
        color: "dark grey grey--text text--darken-1",
      },
      {
        name: "vacation",
        display: "В отпуске",
        color: "dark grey grey--text text--darken-1",
      },
      {
        name: "fired",
        display: "Уволен",
        color: "dark red red--text text--darken-1",
      },
      {
        name: "onduty",
        display: "Работает",
        color: "dark green green--text text--darken-1",
      },
      {
        name: "reserve",
        display: "В резерве",
        color: "dark grey grey--text text--darken-1",
      },
    ],
    eventSelected: {
      _id: -1,
      obj: "",
      name: "asdf",
      message: "",
      date: "",
      source: "",
    },
    eventNew: {
      obj: "",
      name: "",
      message: "",
      date: "",
      source: "",
    },
    events: [],
    employee: {
      body: "",
      name: "",
      customer: "",
      project: "",
      location: "",
      position: "",
      customerrate: "",
    },
    employeeEditing: {
      customer: false,
      project: false,
      location: false,
      position: false,
      customerrate: false,
    },
  }),
  computed: {
    CONFIG() {
      // eslint-disable-next-line no-console
      console.log("CONFIG: ", config.data());
      return config.data();
    },
  },
  methods: {
    isPropertyEditing(index) {
      var result = this.propertiesEditing[index];
      return result;
    },
    getDisplayValue(entity, item) {
      var result;

      switch (item.type) {
        case "string":
          result = entity[item.name];
          break;
        case "json":
          result =
            entity[item.name] && entity[item.name].length > 0
              ? "Объект JSON"
              : null;
          break;
        case "tags":
          result = entity[item.name];
          break;
        case "currency":
          result = parseInt(entity[item.name] || "0").toLocaleString("ru-RU", {
            style: "currency",
            currency: "RUB",
          });
          break;
      }

      return result;
    },
    validateValue(v, item) {
      var result = true;

      switch (item.type) {
        case "string":
          break;
        case "currency":
          result = parseInt(v || "0") > 0;
          break;
      }

      return result;
    },
    /**
     * Включает режим редактирования для указанного свойства сущности
     */
    togleEditProperty(index) {
      // установить режим редактирования через механику переключателя
      Vue.set(
        this.propertiesEditing,
        index,
        this.propertiesEditing[index] == undefined
          ? true
          : !this.propertiesEditing[index]
      ); // todo propertiesEditingMode
      ////this.propertiesEditing[index] = this.propertiesEditing[index] == undefined ? true : !this.propertiesEditing[index];
      // если редактируется новое свойство, которое есть в описании, но еще нет в сущности, то такое свойств надо создать
      if (
        this.propertiesEditing[index] &&
        this.entity[this.properties[index].name] === undefined
      ) {
        this.entity[element.name] = ""; // todo использовать default значение из описания сущности
      }
      console.log(
        `togleEditProperty for ${index} is ${this.propertiesEditing[index]} now.`
      );
    },
    togleEditPropertySwitchOff() {
      this.propertiesEditing = [];
    },
    emit(eventName, value) {
      return new Promise((resolve, reject) => {
        this.$emit(eventName, value);
        this.$nextTick(resolve);
      });
    },
    addNewItem() {
      console.log("addNewItem " + this.newItem);
      this.emit("onNewItemSubmit", this.newItem).then(() => {
        console.log("switch off edit mode");
        this.isNewItemMode = false;
      });
    },
    onCancelEditEvent() {
      console.log(`onCancelEditEvent()`);
      this.selectedEvent = -1;
    },
    onSelectEvent(item) {
      console.log(`onSelectEvent(${JSON.stringify(item)})`);
      // check
      if (item.resolution != null) {
        console.log("Event already have solution");
        return;
      }
      this.eventSelected = item;
      // save selected index
      this.selectedEvent = item._id;
    },
    confirmEvent(id) {
      console.log(`confirmEvent(${id})`);
      // update on server
      fetch(`${API_URL_EVENTS_RESOLUTION}?id=${id}&value=confirmed`)
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          // update collection element
          this.events = this.events.map((i) => {
            // eslint-disable-next-line no-console
            console.log(i);
            return i._id == id ? r : i;
          });
        });
    },
    declineEvent(id) {
      // eslint-disable-next-line no-console
      console.log(`declineEvent(${id})`);
      // update on server
      fetch(`${API_URL_EVENTS_RESOLUTION}?id=${id}&value=declined`)
        .then((r) => r.json())
        .then((r) => {
          // eslint-disable-next-line no-console
          console.log(r);
          // update collection element
          this.events = this.events.map((i) => {
            // eslint-disable-next-line no-console
            console.log(i);
            return i._id == id ? r : i;
          });
        });
    },
    addEvent(result) {
      // eslint-disable-next-line no-console
      console.log(`addEvent(${JSON.stringify(result)})`);
      // add new message on a page
      if (result.details == null) {
        this.events.push(result);
      }
    },
    updateEvent(result) {
      // eslint-disable-next-line no-console
      console.log(`updateEvent(${result})`);
      // update the event in the view
      this.events = this.events.map((i) => {
        // eslint-disable-next-line no-console
        console.log(i);
        return i._id == result._id ? result : i;
      });
      // disbale editing
      this.selectedEvent = -1;
    },
    addEventSubmit() {
      // link to current employee
      this.event.obj = this.$route.params.id;
      // eslint-disable-next-line no-console
      console.log(this.event);
      // make post request
      fetch(API_URL_EVENTS, {
        method: "POST",
        body: JSON.stringify(this.event),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          // eslint-disable-next-line no-console
          console.log("response is");
          // eslint-disable-next-line no-console
          console.log(result);
          // add new message on a page
          if (result.details == null) {
            this.events.push(result);
            this.addNewEventMode = 0;
            // clear (shomehow to be cleared TODO)
            this.event.name = " ";
          }
        });
    },
    ___isChangingEntityValue(index) {
      let name = this.properties[index].name;
      if (this.entityCopy[name] == undefined) {
        this.entityCopy[name] = this.entity[name] || "";
        this.entity[name] = this.entity[name] || "";
      }
      console.log(`this.entityCopy is ${JSON.stringify(this.entityCopy)}`);
      return (
        this.entityCopy[name] != undefined &&
        this.entity[name] != this.entityCopy[name]
      );
    },
    /**
     * Заполнет редактируемую сущность отсутствующими свойствами из ее спецификации
     * todo еще бы удалять несущетсвующие свойства из сущностей
     */
    ___normalizeEntities() {
      console.group("normalizeEntities");
      this.properties.forEach((element) => {
        if (this.entity[element.name] === undefined) {
          this.entity[element.name] = ""; // todo использовать default значение из описания сущности
        }
        if (this.entityCopy[element.name] === undefined) {
          this.entityCopy[element.name] = this.entity[element.name];
        }
      });
      console.log(`entityCopy is ${JSON.stringify(this.entityCopy)}`);
      console.log(`entity is ${JSON.stringify(this.entity)}`);
      console.groupEnd();
    },
    // восстановление вводимого значения из копии
    cancelEditingEntity(index) {
      // закрытие редактирования
      this.togleEditProperty(index);
      // восстановление значения до редактирования
      let name = this.properties[index].name;
      if (this.entityCopy[name] !== undefined) {
        this.entity[name] = this.entityCopy[name];
      }
    },
    _saveEntity() {
      this.entityCopy = Object.assign({}, this.entity);
    },
    /**
     * Сохраняет сущность на сервере
     */
    updateEntity() {
      console.group(`${this.name}.updateEntity()`);
      try {
        console.log(`properties is ${JSON.stringify(this.properties)}`);
        console.log(`entity is ${JSON.stringify(this.entity)}`);
        //this.$emit("uploaded", [].concat(x)); // fire an event
        if (typeof this.save === "undefined") {
          console.warn("save function isn't defined");
          return;
        }
        // save
        this.save(this.entity);
        // todo add .then
        this._saveEntity();

        this.togleEditPropertySwitchOff();
      } catch (err) {
        console.error(err);
      } finally {
        console.groupEnd();
      }
    },
    submitEntity() {
      console.log("Want to submit");
    },
  },

  /*  watch: {
    entity: 
  }*/

  updated: function () {
    //    this.$nextTick(() => {console.log(`updated ${JSON.stringify(this.entity)}`)});
  },

  mounted: function () {
    // let self = this;
    // self.$nextTick(() => {console.log(`mounted ${JSON.stringify(self.entity)}`)});
  },
};
</script>
